import React, { useState, useEffect, useRef } from "react";
import "./Projects.css";

function ProjectsWebdev({
  title,
  info,
  image,
  images,
  description,
  collaborators,
  techStacks,
  link,
}) {
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);

  const handleShowPopup = () => setShowPopup(true);
  const handleClosePopup = () => setShowPopup(false);

  useEffect(() => {
    function handleClickOutside(event) {
      console.log("popupRef:", popupRef.current);
      console.log("event.target:", event.target);
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClosePopup();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef, handleClosePopup]);

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % 3);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [index]);

  return (
    <div className="col-sm-6 col-md-6 mb-4 pb-2">
      <div className="card" onClick={handleShowPopup}>
        <img className="card-img-top" src={image} alt="Card cap" />
        <div className="card-body d-flex flex-column">
          <h5 className="card-title">{title}</h5>
          <p className="card-info mb-0 mt-auto">{info}</p>{" "}
        </div>
      </div>

      {showPopup && (
        <div className="popup">
          <div ref={popupRef} className="popup-inner">
            <button className="popup-close" onClick={handleClosePopup}>
              x
            </button>
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                {images.map((image) => (
                  <div
                    key={image.id}
                    className={`carousel-item ${
                      image.id === 1 ? "active" : ""
                    }`}
                  >
                    <img
                      src={image.src}
                      className="d-block w-100"
                      alt={image.alt}
                    />
                  </div>
                ))}
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
              </button>
            </div>
            <div className="text-center pt-2">
              <h4 className="tech-title">{title}</h4>

              <p className="pt-2">{description}</p>
              <h6 className="tech-title">Developed and designed by:</h6>
              <p>{collaborators}</p>

              <h6 className="tech-title">Tech Stacks that I used:</h6>
              <div className="tech-stack">
                {techStacks.map((tech) => (
                  <span key={tech} className="tech">
                    {tech}
                  </span>
                ))}
              </div>
              <h6 className="tech-title pt-2">See it on github:</h6>
              <a href={link} rel="noopener noreferrer" target="_blank">
                {" "}
                <i className="fa-brands fa-github"></i>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProjectsWebdev;
