import React from "react";
import "./ArcPage.css";
import portfolio_1 from "../../assets/portfolio_1.png";
import portfolio_2 from "../../assets/portfolio_2.png";
import portfolio_3 from "../../assets/portfolio_3.webp";
import ProjectsArc from "../../components/projects/ProjectsArc";

function Arc() {
  return (
    <div className="container container-projects">
      <div className="row">
        <h7 className="text-center pb-4 projects-title">
          Architectural Projects
        </h7>
        <ProjectsArc
          title="Architectural Portfolio / 2018"
          image={portfolio_1}
          link="https://issuu.com/gulsahbayazit/docs/issu2018_bayazit_gulsah_portfolio"
        />
        <ProjectsArc
          title="Architectural Portfolio / 2017"
          image={portfolio_2}
          link="https://issuu.com/gulsahbayazit/docs/portfolio"
        />
        <ProjectsArc
          title="P'yan Dang School Project / 2017"
          image={portfolio_3}
          link="https://www.designboom.com/architecture/pyan-school-project-in-thailand-created-by-students-07-03-2017/"
        />
      </div>
    </div>
  );
}

export default Arc;
