import React, { useState } from "react";
import "../projects/Projects.css";

function ProjectsArc({ title, image, link }) {
  return (
    <div className="col-sm-6 col-md-6 mb-4 pb-2">
      <a href={link} rel="noopener noreferrer" target="_blank">
        <div className="card">
          <img className="card-img-top" src={image} alt="Card cap" />
          <div className="card-body">
            <h5 className="card-title text-center">{title}</h5>
          </div>
        </div>
      </a>
    </div>
  );
}

export default ProjectsArc;
