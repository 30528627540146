import React from "react";
import Projects from "../../components/projects/ProjectsDesign";
import "./DesignPage.css";
import artcode_1 from "../../assets/artcode_1.webp";
import artcode_2 from "../../assets/artcode_2.webp";
import ecoco_1 from "../../assets/ecoco_1.webp";
import ecoco_2 from "../../assets/ecoco_2.webp";
import ecoco_3 from "../../assets/ecoco_3.webp";
import ecoco_4 from "../../assets/ecoco_4.webp";
import ecoco_5 from "../../assets/ecoco_5.webp";
import project_1 from "../../assets/project_design_1.webp";
import project_2 from "../../assets/project_design_2.webp";
import project_design_1_1 from "../../assets/project_design_1_1.webp";
import project_design_1_2 from "../../assets/project_design_1_2.webp";
import project_design_1_3 from "../../assets/project_design_1_3.webp";
import project_design_1_4 from "../../assets/project_design_1_4.webp";
import persona2 from "../../assets/persona2.webp";
import userFlow from "../../assets/userflow.png";
import wireframe2 from "../../assets/wireframe2.webp";
import highfidelity2 from "../../assets/highdifelity2.webp";

function Design() {
  return (
    <div className="container container-projects">
      <div className="row">
        <h7 className="text-center pb-4 projects-title">
          UX/UI Design Projects
        </h7>
        <Projects
          title="Artcode App"
          image={project_1}
          description="Have you ever been frustrated in an art gallery because you didn't have enough information about the artwork or couldn't see the small info table because of the crowd or thought, What does this painting mean? Who created it and when? Most of us ask such questions in an art gallery.
          There is the artwork mobile application “Artistry” for that. It will give users a guide in a gallery or museum that concerns users who want to learn more about artworks by giving users a quick overview."
          projectImage1={project_design_1_1}
          description2="After the secondary research and conducting Interviews, I come out with the problem of visiting art galleries. Art galleries and museums provide one way experience , where they see the arts and couldn`t feel connected. Visitors are frustrated by lack of information. They couldnt find  info for each level of art interest.
          Due to negative experiences, they try to find solutions by using their own phone or data."
          problem="How could I create a product that increases the visitor's interaction with the artwork?"
          userFlowImage={project_design_1_2}
          wireFrameImage={project_design_1_3}
          highFidelityImage={project_design_1_4}
          collaborators="Gülsah Bayazit"
          designTools={["Figma"]}
          images={[
            { id: 1, src: artcode_1, alt: "Image 1" },
            { id: 2, src: artcode_2, alt: "Image 2" },
          ]}
        />
        <Projects
          title="Ecoco Website"
          image={project_2}
          description="Ecoco is a cotton towel website. It gives user more information and trust about the product that they bought"
          problem="How could I create a product that increases the user's trust and interest on cotton towels?"
          projectImage1={persona2}
          description2="In the market research phase, a variety of  shops were researched, to get a general sense of the market. During the research, it was observed that there are popular companies like Amzon, Zalando that people could buy any kind of things.  But they are not promising the quality of the product each time. Attitudes and perception of cotton flowers as gifts are mostly positive, but they are still concerned with the price and longevity of products. An important way to improve the purchasing of product that should be giving more information about the product. "
          userFlowImage={userFlow}
          wireFrameImage={wireframe2}
          highFidelityImage={highfidelity2}
          collaborators="Gülsah Bayazit"
          designTools={["Figma"]}
          images={[
            { id: 1, src: ecoco_1, alt: "Image 1" },
            { id: 2, src: ecoco_2, alt: "Image 2" },
            { id: 3, src: ecoco_3, alt: "Image 3" },
            { id: 4, src: ecoco_4, alt: "Image 4" },
            { id: 5, src: ecoco_5, alt: "Image 5" },
          ]}
        />
      </div>
    </div>
  );
}

export default Design;
