import React from "react";
import "./AboutPage.css";
import CV from "../../assets/cv.pdf";
import "@fortawesome/fontawesome-free/css/all.css";

function AboutPage() {
  const handleButtonClick = () => {
    window.scrollBy({
      top: window.innerHeight * 0.9,
      behavior: "smooth",
    });
  };
  return (
    <div className="row d-flex align-items-stretch">
      <div className="col-sm-12 col-md-8 bg-color">
        <h1 className="about-head">Hi, I'm Gülşah</h1>
        <p className="about-p">
          I am a passionate Frontend Developer and Designer based in Munich.
          With a background in Architecture and Cultural Heritage, I have spent
          years solving problems in the built environment. Now, I apply my
          skills to create digital products and solve problems in the digital
          world.
          <br />
          Programming and design are my passions, and I am dedicated to
          delivering high-quality work that meets my clients' needs.
        </p>
        <div className="d-flex about-btn">
          <div className=" me-2">
            <button onClick={handleButtonClick} className="btn btn-light">
              Learn more
            </button>
          </div>
          <div className=" me-2">
            <a href={CV} download className="btn btn-light">
              Download CV
            </a>
          </div>
        </div>
        <div className="d-flex pt-2 mt-4 about-link">
          <div className=" me-2">
            <a
              className="link-socialMedia"
              href="https://www.linkedin.com/in/guelsah-bayazit/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i
                className="fa-brands fa-linkedin"
                style={{ color: "#f5eee6" }}
              ></i>
            </a>
          </div>
          <div className=" me-2">
            <a
              className="link-socialMedia"
              href="https://github.com/gulsahbayazit"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i
                className="fa-brands fa-github"
                style={{ color: "#f5eee6" }}
              ></i>
            </a>
          </div>
        </div>
      </div>

      <div className="col-sm-12 col-md-4 bg-image"></div>
      <div className="experience">
        <div className="row">
          <div className="col-sm-12 experience-container col-md-4 text-center">
            <i
              className="fa-solid fa-code i-experience"
              style={{ color: "#080808" }}
            ></i>{" "}
            <a href="/works/webdev" className="experience-link">
              <h4 className="font-weight-bold experience-title">
                Web Developer
              </h4>
            </a>
            <br />
            <p>
              "I am passionate about programming and love the challenge of
              turning my ideas into functional and beautiful websites and
              applications by my problem solving skills."
            </p>
            <h5 className="title">Language I speak:</h5>
            <p>HTML</p>
            <p>CSS3</p>
            <p>Javascript</p>
            <p>React</p>
            <p>Redux</p>
            <p>NodeJS</p>
            <p>MongoDB</p>
            <h5 className="title">Dev Tools:</h5>
            <p>Github, Bootstrap</p>
          </div>{" "}
          <div className="col-sm-12 col-md-4 experience-container  text-center">
            <div>
              <i
                className="fa-solid fa-draw-polygon i-experience"
                style={{ color: "#080808" }}
              ></i>
              <a href="works/design" className="experience-link">
                <h4 className="font-weight-bold experience-title">Designer</h4>
              </a>
              <br />
              <p>
                "I enjoy designing user interfaces and user experience with
                skills in typography, layout, and color theory, I'm able to
                create unique and effective designs."
              </p>
              <h5 className="title">Tools I design with:</h5>
              <p>Figma</p>
              <p>Sketch</p>
              <p>Adobe Photoshop</p>
              <p>Adobe Indesign</p>
              <h5 className="title">Things I enjoy doing:</h5>
              <p>
                UI Design, User Testing, <br /> User Story
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 experience-container  text-center">
            <i
              className="fa-solid fa-pen-ruler i-experience"
              style={{ color: "#080808" }}
            ></i>
            <a href="/works/arc" className="experience-link">
              <h4 className="font-weight-bold experience-title">Architect</h4>{" "}
            </a>
            <br />
            <p>
              "As an experienced architect with many years of background, I have
              honed my skills in problem-solving and design to create visually
              appealing solutions."
            </p>
            <h5 className="title">Tools I draw with:</h5>
            <p> Archicad</p>
            <p>Autocad</p>
            <p>Artlantis</p>
            <h5 className="title">Project Types</h5>
            <p>
              Hotels, Housing, Stadium, <br /> Cultural Houses <br /> Religious
              Buildings
            </p>
          </div>{" "}
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
