import React from "react";
import { DotLoader } from "react-spinners";
import "./LoadingSpinner.css"; // Import the CSS file

const LoadingSpinner = ({ isLoading }) => {
  return (
    <div className="loading-spinner-container">
      <DotLoader color={"#c83c27"} loading={isLoading} size={60} />
    </div>
  );
};

export default LoadingSpinner;
