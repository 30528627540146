import "./ContactPage.css";
import React, { useState } from "react";
import emailjs, { send } from "emailjs-com";

function ContactPage() {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isEmailSending, setIsEmailSending] = useState(false);
  const [toSend, setToSend] = useState({
    first_name: "",
    last_name: "",
    message: "",
    email: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();
    const Service_ID = "service_form";
    const Template_ID = "template_jehsdag";
    const Public_Key = "vujP7Q5vLUCUmp7LO";

    send(Service_ID, Template_ID, toSend, Public_Key)
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setIsEmailSent(true);
        setToSend({
          first_name: "",
          last_name: "",
          message: "",
          email: "",
        });
        setTimeout(() => {
          setIsEmailSent(false);
        }, 7000);
      })
      .catch((err) => {
        console.log("FAILED...", err);
      })
      .finally(() => {
        setTimeout(() => {
          setIsEmailSending(false);
        }, 500);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const handleCloseOverlay = () => {
    window.location.reload();
  };
  return (
    <div className="contact-container">
      <div className="container contact mt-5">
        <h3 className="pt-4 contact-h3">Contact Us</h3>
        <p className="pb-2 contact-p">
          Please fill this form in a decent manner
        </p>
        <div className="contact-form-container">
          <div className="form-wrapper">
            {isEmailSent ? (
              <div className="email-gif-overlay">
                <img src={require("../../assets/email.gif")} alt="gif" />
                <p>Thank you, I received your email.</p>
              </div>
            ) : null}
            <form onSubmit={onSubmit}>
              <div className="mb-3">
                <label className="form-label" htmlFor="name">
                  First Name
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="first_name"
                  required
                  placeholder="First name"
                  value={toSend.first_name}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="name">
                  Last Name
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="last_name"
                  required
                  placeholder="Last name"
                  value={toSend.last_name}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="email">
                  Email
                </label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  required
                  placeholder="Your email"
                  value={toSend.email}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="message">
                  Message
                </label>
                <textarea
                  className="form-control"
                  type="text"
                  name="message"
                  required
                  placeholder="Message"
                  value={toSend.message}
                  onChange={handleChange}
                />
              </div>
              <button className="btn" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
