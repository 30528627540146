import "./HomePage.css";
import React from "react";
import { useState, useEffect } from "react";

function HomePage() {
  const [index, setIndex] = useState(0);
  const text1 = ["DEVELOPER", "DESIGNER"];
  const text2 = ["DESIGNER", "DEVELOPER"];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((index + 1) % text1.length);
    }, 3000);
    return () => clearInterval(intervalId);
  }, [index, text1.length]);

  return (
    <div className="home-container ">
      <div className="head justify-content-center align-items-center">
        <div className="row justify-content-center flex-md-row flex-column">
          <div className="col-12 col-md-6">
            <div className="row">
              <h1 className="changing-text">{text2[index]}</h1>

              <h1 className="changing-text">{text1[index]}</h1>
            </div>
          </div>
          <div className="col-12 col-md-6 align-self-center">
            <div className="row">
              <h4 className="home-h4">
                I am a, <br />{" "}
                <span className="home-span">
                  {" "}
                  Frontend Developer & Designer
                </span>
                <br /> with Architecture background <br /> based in{" "}
                <span className="home-span">Munich, Germany.</span> <br /> I'm
                passionate about creating
                <br /> beautiful and functional websites <br /> using the latest
                technologies.
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default HomePage;
