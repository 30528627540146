import "./Navbar.css";
import logo from "../../assets/logo.png";
import whiteLogo from "../../assets/whiteLogo.png";
import { useState, useEffect } from "react";
import React from "react";

function Navbar(props) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const currentPage = window.location.pathname;

  const isActive = (page) => {
    return currentPage === page ? "active-nav" : "";
  };

  const isWhiteNavbar =
    currentPage === "/" ||
    currentPage === "/about" ||
    currentPage === "/contact";

  const navClassName = `nav justify-content-end ${
    isWhiteNavbar ? "white-nav" : "black-nav"
  }`;

  const isWhiteLogo = currentPage === "/about" || currentPage === "/contact";

  const logoSrc = isWhiteLogo ? whiteLogo : logo;

  return (
    <>
      <a href="/">
        <img className="logo" src={logoSrc} alt="Logo" />
      </a>

      <ul className={navClassName}>
        <li className={`nav-item ${isActive("/about")}`}>
          <a className="nav-link" href="/about" data-name="About">
            About
          </a>
        </li>
        <li
          className={`nav-item dropdown ${isActive("/works/webdev")} ${isActive(
            "/works/design"
          )} ${isActive("/works/arc")} `}
        >
          <a
            className="nav-link dropdown-toggle"
            data-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="true"
            aria-expanded={isOpen ? "true" : "false"}
            onClick={toggleMenu}
            data-name="Works"
          >
            Works
          </a>
          <div className={`dropdown-menu ${isOpen ? "show" : ""}`}>
            <a className="dropdown-item" href="/works/webdev">
              Web Development
            </a>
            <a className="dropdown-item" href="/works/design">
              UX/UI Design
            </a>
            <a className="dropdown-item" href="/works/arc">
              Architecture
            </a>
          </div>
        </li>

        <li className={`nav-item ${isActive("/contact")}`}>
          <a className="nav-link" href="/contact" data-name="Contact">
            Contact
          </a>
        </li>
      </ul>
    </>
  );
}

export default Navbar;
