import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import React, { useState, useEffect } from "react";

// Components
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import LoadingSpinner from "./components/loadingSpinner/LoadingSpinner";

// Pages
import AboutPage from "./pages/about/AboutPage";
import ContactPage from "./pages/contact/ContactPage";
import HomePage from "./pages/home/HomePage";
import WebdevPage from "./pages/works-webdev/WebdevPage";
import DesignPage from "./pages/works-design/DesignPage";
import ArcPage from "./pages/works-arc/ArcPage";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay for demonstration purposes
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      {!isLoading && (
        <>
          <Navbar />
          <Router>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/works/webdev" element={<WebdevPage />} />
              <Route path="/works/design" element={<DesignPage />} />
              <Route path="/works/arc" element={<ArcPage />} />
              <Route path="/contact" element={<ContactPage />} />
            </Routes>
          </Router>
          <Footer />
        </>
      )}
    </>
  );
}

export default App;
