import "./Footer.css";
import React from "react";

function Footer() {
  const currentPage = window.location.pathname;
  const isWhiteFooter =
    currentPage === "/" ||
    currentPage === "/contact" ||
    currentPage === "/about";
  const footerClassName = `footer mt-auto py-3 ${
    isWhiteFooter ? "white-footer" : "black-footer"
  }`;

  return (
    <footer className={footerClassName}>
      <span>
        © {new Date().getFullYear()} Designed & Built with &hearts; by Gülşah
        Bayazit
      </span>
    </footer>
  );
}

export default Footer;
